import React from 'react';
import SvgIcon from '@mui/material/SvgIcon';
import { Button as IconButton } from '@mui/material';
/*import axios from 'axios';
import { ReactComponent as icon1 } from '../../images/CreativeLayoutIcon1.svg';
import { ReactComponent as icon2 } from '../../images/CreativeLayoutIcon2.svg';*/

/**
 * 
 * @param {*} props 
 * @returns 
 * 
 *  layout	feft		right		fullscreen
 *  ----------------------------------------------
 *	1		stylo		formx
 *	2		formx		stylo
 *	3		formx		preview
 *	4								stylo
 *	5								formx
 *	6								zoom(1)
 *	7								zoom(2)
 *	8								zoom(3)
 */
export const CreativeLayouts = (layout, props) => {

    /*   const [iconL1, setIconL1] = useState('');
       const [iconL2, setIconL2] = useState('');
   
       useEffect(() => {
           downloadSvg('/webstore/resources/images/CreativeLayoutIcon1.svg', 1);
           downloadSvg('/webstore/resources/images/CreativeLayoutIcon2.svg', 2);
       }, []);
   
       async function downloadSvg(url, l) {
           let response = await axios.get(url);
           if (response?.headers['content-type'] !== "image/svg+xml") {
               if (l === 1) {
                   setIconL1('');
               } else if (l === 2) {
                   setIconL2('');
               }
           } else {
               let svgXml = response.data;
               console.log("Svg xml:", svgXml);
               if (l === 1) {
                   setIconL1(svgXml);
               } else if (l === 2) {
                   setIconL2(svgXml);
               }
           }
   
       } */

    const l1 = {
        icon: () => {
            return (
                <img style={{ maxHeight: 20 }} src="/webstore/resources/images/CreativeLayoutIcon1.svg" alt="Layout" />

            )
        },
        stylo: {
            width: '66%',
            height: '100%',
            order: 1
        },
        form: {
            width: '34%',
            order: 2
        },
        preview: {
            display: 'none'
        },
        creativetools: {
            direction: 'column',
            style: {
                width: '100%'
            }
        }
    };
    const l2 = {
        icon: () => {
            return (
                <img style={{ maxHeight: 20 }} src="/webstore/resources/images/CreativeLayoutIcon2.svg" alt="Layout" />)
        },
        stylo: {
            width: '34%',
            order: 1
        },
        form: {
            width: '66%',
            order: 2
        },
        preview: {
            display: 'none'
        },
        creativetools: {
            direction: 'row',
            style: {
                width: '50%'
            }
        }
    };
    const l3 = {
        icon: () => {
            return (
                <SvgIcon fontSize='small' {...props}>
                    <path d="M0 4 L24 4 L24 20 L0 20 M1 5 L1 19 L23 19 L23 5  M3 7 L6 7 L6 8 L3 8 M8 7 L11 7 L11 8 L8 8 M13 7 L16 7 L16 8 L13 8  M3 10 L6 10 L6 11 L3 11 M8 10 L11 10 L11 11 L8 11 M13 10 L16 10 L16 11 L13 11  M3 13 L6 13 L6 14 L3 14 M8 13 L11 13 L11 14 L8 14 M13 13 L16 13 L16 14 L13 14  M3 16 L6 16 L6 17 L3 17 M8 16 L11 16 L11 17 L8 17 M13 16 L16 16 L16 17 L13 17 " />
                </SvgIcon>)
        },
        stylo: {
            position: 'absolute',
            top: '-10000px',
            order: 3
        },
        form: {
            width: '66%',
            order: 2
        },
        preview: {
            width: '34%',
            order: 1,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
        },
        creativetools: {
            direction: ''
        }
    };
    const l4 = {
        icon: () => {
            return (
                <SvgIcon fontSize='small' {...props}>
                    <path d="M0 4 L24 4 L24 20 L0 20 M1 5 L1 19 L23 19 L23 5 M3 7 L21 7 L21 17 L3 17z" />
                </SvgIcon>)
        },
        stylo: {
            width: '100%',
            order: 1
        },
        form: {
            display: 'none'
        },
        preview: {
            display: 'none'
        },
        creativetools: {
            direction: ''
        }
    };
    const l5 = {
        icon: () => {
            return (
                <SvgIcon fontSize='small' {...props}>
                    <path d="M0 4 L24 4 L24 20 L0 20 M1 5 L1 19 L23 19 L23 5  M3 7 L6 7 L6 8 L3 8 M8 7 L11 7 L11 8 L8 8 M13 7 L16 7 L16 8 L13 8 M18 7 L21 7 L21 8 L18 8  M3 10 L6 10 L6 11 L3 11 M8 10 L11 10 L11 11 L8 11 M13 10 L16 10 L16 11 L13 11 M18 10 L21 10 L21 11 L18 11  M3 13 L6 13 L6 14 L3 14 M8 13 L11 13 L11 14 L8 14 M13 13 L16 13 L16 14 L13 14 M18 13 L21 13 L21 14 L18 14  M3 16 L6 16 L6 17 L3 17 M8 16 L11 16 L11 17 L8 17 M13 16 L16 16 L16 17 L13 17 M18 16 L21 16 L21 17 L18 17" />
                </SvgIcon>)
        },
        stylo: {
            position: 'absolute',
            top: '-10000px',
            order: 3
        },
        form: {
            width: '100%',
            order: 1
        },
        preview: {
            display: 'none'
        },
        creativetools: {
            direction: ''
        }
    };

    switch (layout) {
        case 1:
            return l1;
        case 2:
            return l2;
        case 3:
            return l3;
        case 5:
            return l5;
        case 4:
            return l4;
        default:
            return l1;
    }
}

export const LayoutSelector = ({ template, setLayout }) => {

    const B1 = () => {
        const layout = 1;
        return template && template.creativeLayouts && template.creativeLayouts.includes(layout) && template._links.formx ?
            (<IconButton variant='layoutCreativePage' sx={{ minWidth: '40px', pl: 0, pr: 0 }} onClick={() => { setLayout(layout); }} component="span">
                {CreativeLayouts(layout).icon()}
            </IconButton>)
            :
            <></>;
    };
    const B2 = () => {
        const layout = 2;
        return template && template.creativeLayouts && template.creativeLayouts.includes(layout) && template._links.formx ?
            (<IconButton variant='layoutCreativePage' sx={{ minWidth: '40px', pl: 0, pr: 0 }} onClick={() => { setLayout(layout); }} component="span">
                {CreativeLayouts(layout).icon()}
            </IconButton>)
            :
            <></>;
    };


    return (
        template && template.creativeLayouts.length > 1 ?
            <>
                <B1></B1>
                <B2></B2>
            </>
            :
            <></>
    );
};
